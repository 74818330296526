import { clsx } from 'clsx'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Post, PostStatus, PostType } from '@tribeplatform/gql-client/types'
import { T } from '@tribeplatform/react-components/i18n'
import { ReplyBar } from '@tribeplatform/react-components/Post'
import {
  ReplyList,
  Reply,
  SortOptionId,
  REPLIES_SECTION_HASH,
} from '@tribeplatform/react-components/Reply'
import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import { Card } from '@tribeplatform/react-ui-kit/Card'

interface Props {
  post: Post
  highlighted: boolean
  stretchX: string
  postTypes: PostType[]
  showReplySortOptions?: boolean
  defaultReplySortId?: SortOptionId
  isSinglePost: boolean
}
export const ReplyListField = ({
  post,
  highlighted,
  stretchX,
  postTypes,
  showReplySortOptions,
  defaultReplySortId,
  isSinglePost,
}: Props) => {
  const { Link } = useRouter()
  const { isGuest } = useAuthMember()
  const [canCreateReply] = hasScopesPermission(post, ['createReply'])
  const validPostTypes =
    postTypes?.find(postType => postType.id === post?.postTypeId)
      ?.validReplyTypes ||
    post.postType?.validReplyTypes ||
    []
  const isDeleted = post.status === PostStatus.DELETED
  let canReply = true
  if (
    (!isGuest && !canCreateReply) ||
    !post.authMemberProps?.availableReplyTypes?.length
  ) {
    canReply = false
  }
  const readonly = false

  if (isSinglePost) {
    return (
      <>
        {isSinglePost &&
          (validPostTypes.length > 0 || post?.replies?.nodes?.length > 0) && (
            <ReplyList
              post={post}
              showReplySortOptions={showReplySortOptions}
              defaultReplySortId={defaultReplySortId}
              className={clsx(
                highlighted &&
                  'ring-2 rounded-sm ring-offset-surface ring-focused',
              )}
            />
          )}
      </>
    )
  }

  return (
    <div
      className={clsx(
        'flex h-full space-s-2 text-content-subdued items-center',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused m-1',
        stretchX,
      )}
    >
      {(canReply || post?.replies?.nodes?.length > 0) && (
        <Card.Content
          className={clsx(
            'flex flex-col space-y-6 px-5 !pt-0 min-w-0',
            !post?.replies?.nodes?.length && 'sm:p-4 sm:px-6 py-4',
          )}
        >
          {post?.repliesCount > 2 ? (
            <Link
              variant="neutral"
              className="text-sm leading-5 font-medium text-content-subdued"
              href={
                post?.relativeUrl
                  ? `${post?.relativeUrl}${REPLIES_SECTION_HASH}`
                  : ''
              }
            >
              <T
                id="Post.PostCard.ViewMoreXReplysText"
                defaultMessage="View {more_replies_count} more replies"
                values={{ more_replies_count: post?.repliesCount - 2 }}
              />
            </Link>
          ) : null}
          <div className="relative flex flex-col space-y-6">
            {post?.replies?.nodes
              ?.map(reply => (
                <Reply
                  reply={reply}
                  key={reply.id}
                  canReply={!readonly}
                  readonly={readonly}
                  nestLevel={1}
                />
              ))
              .reverse()}
            {!readonly && canReply && !isDeleted && <ReplyBar post={post} />}
          </div>
        </Card.Content>
      )}
    </div>
  )
}
